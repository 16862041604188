<template>
  <div>
    <div class="row">
      <ca-popup
        header="Payment Receipt"
        :use-close-action="false"
        :toggle="openPopup"
        @open="openPaymentHistoryPopup"
        @close="closePaymentHistoryPopup"
        v-show="currentPopupData !== null"
      >
        <template v-slot:content>
          <div class="table r-table">
            <div class="r-table__tr">
              <div class="r-table__th">
                <p>User Name</p>
              </div>
              <div class="r-table__td">
                <p>
                  <span
                    v-if="
                      currentPopupData.name !== null &&
                      currentPopupData.name !== ''
                    "
                  >
                    {{ currentPopupData.name }}</span
                  >
                  <span
                    v-else-if="
                      currentPopupData.first_name !== null &&
                      currentPopupData.first_name !== ''
                    "
                  >
                    {{ currentPopupData.first_name }}
                  </span>
                  <span
                    v-else-if="
                      currentPopupData.last_name !== null &&
                      currentPopupData.last_name !== ''
                    "
                  >
                    {{ currentPopupData.last_name }}
                  </span>
                  <span v-else> No Name</span>
                  <router-link
                    :to="{
                      name: 'vu-edit-user',
                      params: { id: currentPopupData.id },
                    }"
                    class="r-table__link"
                    >view User</router-link
                  >
                </p>
              </div>
            </div>

            <div class="r-table__tr">
              <div class="r-table__th"><p>Date of purchase</p></div>
              <div class="r-table__td">
                <p>{{ currentPopupData.created_at | parseDate }}</p>
              </div>
            </div>
            <div class="r-table__tr">
              <div class="r-table__th"><p>Subscription</p></div>
              <div class="r-table__td"><p>Caena Growth</p></div>
            </div>
            <div class="r-table__tr">
              <div class="r-table__th"><p>Payment Method</p></div>
              <div class="r-table__td">
                <p>{{ currentPopupData.payment_method }}</p>
              </div>
            </div>

            <div class="r-table__tr">
              <div class="r-table__th"><p>Reference number</p></div>
              <div class="r-table__td">
                <p>{{ currentPopupData.transaction_id }}</p>
              </div>
            </div>

            <div class="r-table__tr">
              <div class="r-table__th"><p>Expiry Date</p></div>
              <div class="r-table__td"><p>10/07/2021</p></div>
            </div>

            <div class="r-table__tr">
              <div class="r-table__th"><p>Total Fee</p></div>
              <div class="r-table__td">
                <p>£{{ currentPopupData.amount }}</p>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:actions>
          <ca-button @click.native="downloadPdf">Download Receipt</ca-button>
        </template>
      </ca-popup>
      <div class="col-xs-12">
        <page-header
          :title="'Payment History'"
          :sub-heading="true"
          :sub-header="'Dashboard'"
          :link-name="'Payment History'"
          :link-url="'/payment-history'"
        >
        </page-header>

        <full-page-content-container>
          <grid-table
            :column-defs="columnDefs"
            :row-data="rowData"
            v-if="rowData.length > 0"
          >
            <template v-slot:cell-renderer="slotProps">
              <span
                class="icon-button"
                @click="openPaymentHistoryPopup(slotProps.currentRowData)"
                ><img
                  :src="require('@/assets/images/icons/view.svg')"
                  alt="view icon"
              /></span>
            </template>
          </grid-table>
        </full-page-content-container>

        <vue-html2pdf
         
          :filename="'payment-history'+ currentPopupData.name + '-' + currentPopupData.id + '.pdf'"
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="false"
          :pdf-quality="2"
          :manual-pagination="true"
          pdf-format="a4"
          pdf-orientation="portrait"
          id="PdfPrint"
          ref="pdf"
          
         >
         <section slot="pdf-content">
        <section slot="pdf-item" style="padding:0px 26px;color:black;position:relative; height:auto">

          <div class="table r-table">
            <div class="r-table__tr">
              <div class="r-table__th">
                <p>User Name</p>
              </div>
              <div class="r-table__td">
                <p>
                  <span
                    v-if="
                      currentPopupData.name !== null &&
                      currentPopupData.name !== ''
                    "
                  >
                    {{ currentPopupData.name }}</span
                  >
                  <span
                    v-else-if="
                      currentPopupData.first_name !== null &&
                      currentPopupData.first_name !== ''
                    "
                  >
                    {{ currentPopupData.first_name }}
                  </span>
                  <span
                    v-else-if="
                      currentPopupData.last_name !== null &&
                      currentPopupData.last_name !== ''
                    "
                  >
                    {{ currentPopupData.last_name }}
                  </span>
                  <span v-else> No Name</span>
                </p>
              </div>
            </div>

            <div class="r-table__tr">
              <div class="r-table__th"><p>Date of purchase</p></div>
              <div class="r-table__td">
                <p>{{ currentPopupData.created_at | parseDate }}</p>
              </div>
            </div>
            <div class="r-table__tr">
              <div class="r-table__th"><p>Subscription</p></div>
              <div class="r-table__td"><p>Caena Growth</p></div>
            </div>
            <div class="r-table__tr">
              <div class="r-table__th"><p>Payment Method</p></div>
              <div class="r-table__td">
                <p>{{ currentPopupData.payment_method }}</p>
              </div>
            </div>

            <div class="r-table__tr">
              <div class="r-table__th"><p>Reference number</p></div>
              <div class="r-table__td">
                <p>{{ currentPopupData.transaction_id }}</p>
              </div>
            </div>

            <div class="r-table__tr">
              <div class="r-table__th"><p>Expiry Date</p></div>
              <div class="r-table__td"><p>10/07/2021</p></div>
            </div>

            <div class="r-table__tr">
              <div class="r-table__th"><p>Total Fee</p></div>
              <div class="r-table__td">
                <p>£{{ currentPopupData.amount }}</p>
              </div>
            </div>
          </div>

        </section>
         </section>


        </vue-html2pdf>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../layouts/components/fullPage/PageHeader";
import FullPageContentContainer from "../layouts/components/fullPage/FullPageContentContainer";
import GridTable from "../components/tables/GridTable";
import axios from "../../axios";
import gridTableParentMixin from "../../mixins/gridTableParentMixin";
import CaPopup from "../components/popup/CaPopup";
import CaButton from "../components/buttons/CaButton";
import VueHtml2pdf from 'vue-html2pdf';


export default {
  mixins: [gridTableParentMixin],
  name: "PaymentHistory",
  components: {
    CaButton,
    CaPopup,
    GridTable,
    FullPageContentContainer,
    PageHeader,
    VueHtml2pdf,
  },
  data() {
    return {
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Name",
          field: "name",
          filter: true,
          sortable: true,
          hasOptionalDataFields: true,
          optionalDataFields: ["first_name", "last_name"],
        },
        {
          headerName: "Amount",
          field: "amount",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Transaction ID",
          field: "transaction_id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Payment Method",
          field: "payment_method",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Date",
          field: "created_at",
          type: "date",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Actions",
          field: "actions",
          hasCellRendererFramework: true,
        },
      ],
      rowData: [],
      dataResourceUrl: `${this.$store.state.server.requestUri}/admin/payment-histories`,
      openPopup: false,
      currentPopupData: {},
    };
  },

  methods: {
    viewUser(userID) {
      this.$router.push({ name: "vu-edit-user", params: { id: userID } });
    },
    getTableData(resourceUrl) {
      const loading = this.$vs.loading();
      axios
        .get(`${resourceUrl}`)
        .then((res) => {
          this.rowData = res.data.data;
          console.log(this.rowData);
          loading.close();
        })
        .catch((err) => {
          loading.close();
          console.log(err.response);
        });
    },

    openPaymentHistoryPopup(popUpData = null) {
      if (popUpData !== null) {
        this.currentPopupData = popUpData;
      }
      this.openPopup = true;
    },

    closePaymentHistoryPopup() {
      this.openPopup = false;
    },
    downloadPdf() {
      this.$refs.pdf.generatePdf();
    },
  },

  created() {
    this.getTableData(`${this.dataResourceUrl}?${this.defaultQueryString}`);
  },
};
</script>

<style lang="scss" scoped></style>
